.nav {
  align-items: center;
  display: flex;
  height: 126px;
  padding: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 10px
}

.right-nav {
    margin-left: auto;
    padding-right: 62px;
}

.nav-link-selected {
    display: none;
}

#logo {
    height: 74px;
    /* background-color: #1d1d1d; */
    background-color: #0b0b0b;
    padding-bottom: 3px;
    padding-left: 1px;
    border-radius: 146px;
}

#separator {
    height: 44px;
}

#sound-cloud-component-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 29px;
    opacity: 0.87;
    width: min(84vw, 782px);
    padding-left: 10px;
    padding-right: 10px;
}

#contact-container {
    height:100%;
    width:100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#contact-email {
    font-size: 1.2rem;
    user-select: all;
    /* -webkit-text-stroke: 1px white; */
}

@media only screen and (max-width: 960px) {
    .nav {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 126px;
        padding: 24px;
        /* gap: 14.7px; */
        gap: 16.2px;
    }

    .right-nav {
        margin-left: unset;
        padding-right: unset;
    }
}